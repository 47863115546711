
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import { Article, ArticleImageDto } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import ArticleSum from '@/components/articles/ArticleSum.component.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';


/**
 * @desc page d'affichage d'un message à l'utilisateur après un événement(validation, suppression, édition, etc...)
 */
  @Component({
    components: {
      'article-sum' : ArticleSum
    },
  })
  export default class ValidationView extends Vue {
    pageTitle = "";

    reqType = "";
    params : any = {};
    displayedArticle : Article = new Article();

    async mounted() : Promise<void> {
      this.reqType = this.getRequestType() || "";
      switch(this.reqType) {
        case 'article-creation': 
          if(this.params.id) {
            this.displayedArticle = await this.getArticle(this.params.id)
          }
          this.pageTitle = "Article créé avec succès !"
          break;

        case 'article-validation':
          if(this.params.id) {
            this.displayedArticle = await this.getArticle(this.params.id)
          }
          this.pageTitle = "Article publié avec succès !"
          break;

        case 'article-deletion': 
          this.pageTitle = "Article supprimé"
          break;

        case 'draft-article-creation':
          if(this.params.id) {
            this.displayedArticle = await this.getArticle(this.params.id)
          }
          this.pageTitle = "Article créé avec succès";
          break;
      }
    }

    /**
     * @desc récupère les données de la requète url, et le type de validation demandé
     */
    private getRequestType() : string | undefined{
      const query = window.location.search.substr( window.location.search.indexOf('?') + 1);
      const urlSearchParams = new URLSearchParams(query);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.params = params;
      if(params)
        return params.type;

      return '';
    }

    /**
     * @desc charge l'article indiqué
    */ 
    private getArticle(articleId : number) : Promise<any> {
      const headers = AuthenticationService.getRequestHeader();

      return new Promise<any>((resolve, rejects) => {
        axios 
          .get(  UrlConsts.getArticle + '/' + articleId, headers)
        .then((response) => {
          resolve(new Article(response.data))
        })
        .catch(error => {console.log(error), rejects(error)})
      })
    }
  }
